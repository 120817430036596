import { createSlice } from "@reduxjs/toolkit";
// Import the reducer from the separate file
import { initialStates } from "./intialStates";
import { toast } from "react-toastify";
const AuthSlice = createSlice({
  name: "Auth",
  initialState: initialStates,
  reducers: {
    user: (state, action) => {
      state.credentials = action.payload;
    },
    login: (state, action) => {
      state.user = action.payload;
    },
    register: (state, action) => {
      state.user = action.payload;
    },
    logout: (state, action) => {
      state.user = [];
      if (!window?.location?.pathname?.startsWith("/tip-receiver-details/")) {
        toast.success("Logout successfully", {
          autoClose: 5000,
        });
      } // Tip Receiver Details URL)
    },
    update: (state, action) => {
      let user = JSON.parse(JSON.stringify(state.user));

      state.user = {
        ...user,
        user: action.payload,
      };
    },
    updateStripeUser: (state, action) => {
      state.user = {
        ...state,
        user: action.payload,
      };
    },
    addConnectedId: (state, action) => {
      state.connectAccountId = action.payload;
    },
  },
});

export const {
  login,
  logout,
  register,
  update,
  updateStripeUser,
  user,
  addConnectedId,
} = AuthSlice.actions;
export default AuthSlice.reducer;
